/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { resolveImage } from "@dataResolvers";
import { Page as PageTemplate } from "@pageTemplates";
import moment from "moment";
import QueryContainer from "@templates/QueryContainer";

moment.locale("en");

export const query = graphql`
  query CaseStudyIndexQuery($siteId: [Craft_QueryArgument]) {
    craft {
      entry(section: "caseStudiesIndex", siteId: $siteId) {
        ... on Craft_caseStudiesIndex_caseStudiesIndex_Entry {
          title
          slug
          url
          #Hero
          heroTitle
          entrySubtitle
          featuredArticle {
            ... on Craft_caseStudies_caseStudy_Entry {
              url
              title
              heroTitle
              entrySubtitle
              metaDescription
              articleCategory {
                ... on Craft_blog_Category {
                  title
                }
              }
              metaFeaturedImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
          }
          #Form
          heading
          subHeading
          caseStudyFormSubmissionThanksMessage
          caseStudyFormSubmissionEmailRecipients
          #Meta
          metaPageTitle
          metaDescription
          metaFeaturedImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          aboveFooterCtaImageBlocks {
            ...AboveFooterFragment
          }
        }
      }
      entries(section: "caseStudies", type: "caseStudy", siteId: $siteId) {
        ... on Craft_caseStudies_caseStudy_Entry {
          id
          title
          url
          heroTitle
          metaDescription
          articleCategory {
            ... on Craft_blog_Category {
              uid
              title
            }
          }
          postDate
          articleAuthor {
            ... on Craft_author_default_Entry {
              title
            }
          }
          metaFeaturedImage(withTransforms: ["aboveFooterCtaExtraLarge"]) {
            width(transform: "aboveFooterCtaExtraLarge")
            height(transform: "aboveFooterCtaExtraLarge")
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url(transform: "aboveFooterCtaExtraLarge")
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry, entries }) => {
  if (!entry) return null;
  const {
    title,
    slug,
    url,
    heroTitle,
    // aboveFooterCtaImageBlocks,
    entrySubtitle,
    metaPageTitle,
    metaFeaturedImage,
    metaDescription,
    // heading,
    // subHeading,
    // caseStudyFormSubmissionThanksMessage,
    // caseStudyFormSubmissionEmailRecipients,
    featuredArticle,
  } = entry;
  // map case studies
  const caseStudies = entries.map(c => {
    return {
      heading: c?.heroTitle || c?.title,
      summary: c?.metaDescription,
      link: {
        text: `Read More`,
        url: c?.url,
      },
      image: resolveImage(c?.metaFeaturedImage),
      width: "third",
      fullWidthImage: true,
      spacingBottom: true,
      category: c?.articleCategory?.[0]?.title,
      type: c?.articleCategory?.[0],
      author: c?.articleAuthor?.[0]?.title,
      postDate: moment(c?.postDate).format("l"),
    };
  });

  // create empty objects to populate categories in use
  let types = {};

  entries.forEach(c => {
    // resolve the insight
    const newType = {
      [c?.articleCategory?.[0]?.uid]: c?.articleCategory?.[0]?.title,
    };
    // const newTopic = insight.topics.forEach((topic, i) => {
    //   topics[topic.uid] = topic.title;
    // });
    // spread and merge objects to augment list
    types = { ...types, ...newType };
  });

  // return data
  return {
    slug,
    url,
    hero: {
      title:
        featuredArticle?.[0]?.heroTitle ||
        featuredArticle?.[0]?.title ||
        heroTitle ||
        title,
      subtitle:
        featuredArticle?.[0]?.entrySubtitle ||
        featuredArticle?.[0]?.metaDescription ||
        entrySubtitle,
      image: resolveImage(
        featuredArticle?.[0]?.metaFeaturedImage || metaFeaturedImage
      ),
      featured: !!featuredArticle?.[0]?.title,
      label: featuredArticle?.[0]?.articleCategory?.[0]?.title,
      button: {
        url: featuredArticle?.[0]?.url,
        text: `Read Article`,
      },
    },
    filters: {
      insights: types,
    },
    caseStudies,
    // form: {
    //   heading,
    //   subHeading,
    //   thanksMessage: caseStudyFormSubmissionThanksMessage,
    //   recipients: caseStudyFormSubmissionEmailRecipients,
    // },
    // cards: resolveAboveCta(aboveFooterCtaImageBlocks),
    meta: {
      title,
      metaPageTitle,
      metaFeaturedImage: resolveImage(metaFeaturedImage),
      metaDescription,
    },
  };
};

const Page = ({ data, pageContext }) => (
  <QueryContainer
    data={data}
    pageContext={pageContext}
    PageTemplate={PageTemplate}
    dataResolver={dataResolver}
  />
);

export default Page;
